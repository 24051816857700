<!--
 * @Author: 
 * @Date: 2022-11-25 17:18:29
 * @LastEditTime: 2022-11-25 17:34:33
 * @LastEditors: Please set LastEditors
 * @Description: 
 * @FilePath: \OrganizationPC\src\views\marketing\promotionSuccess.vue
 * 
-->
<template>
  <div class="pro-success">
    <div class="succ-icon"><i class="el-icon-success"></i></div>
    <div class="text">{{ text }}</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      text: ""
    }
  },
  created() {
    this.text = this.$route.query.text;
  }
}
</script>
<style lang="less" scoped>
.pro-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 200px;
  background: #fff;
  .succ-icon {
    font-size: 80px;
    color: #67c23a;
  }
  .text {
    font-size: 18px;
    padding-top: 30px;
    color: #333;
  }
}
</style>>